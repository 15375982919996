<template>
  <div>
    <section id="card-style-variation">
      <div class="mb-2">
        <!-- titre -->
        <div>
          <h3 class="my-2">
            Indicateurs sur votre activité (du
            {{ reverseDate(filterBy.periode.from, "/") }} au
            {{ reverseDate(filterBy.periode.to, "/") }})
          </h3>
        </div>

        <!-- <b-row>
          <b-col
            lg="6"
            class="mb-1"
          >
            <b-input-group  prepend="Changer la période">
              <DateRangeWidget
                v-model="filterBy.periode"
                :range="filterBy.periode"
                :custom-placeholder="filterBy.periode"
                placeholder="04-01-2023 au 13-01-2023"
                class="form-control-merge"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click="loadIndicators()"
                >
                  Valider
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row> -->

        <!-- recherche par periode -->
        <div class="d-flex justify-content-between">
          <div class="col-lg-10 col-md-10">
            <b-form-group class="" label-cols-xl="3" label="Changer la période">
              <b-input-group class="col-md-11 col-lg-11">
                <DateRangeWidget
                  v-model="filterBy.periode"
                  :range="filterBy.periode"
                  :custom-placeholder="filterBy.periode"
                  placeholder="04-01-2023 au 13-01-2023"
                />
              </b-input-group>
            </b-form-group>
          </div>
          <!-- buttons -->
          <div class="col-lg-2 col-md-2">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              size="sm"
              variant="outline-primary"
              @click="loadIndicators()"
              class="w-100"
            >
              Valider
            </b-button>
          </div>
        </div>
      </div>

      <hr />

      <div v-if="loadingIndicators" class="text-center">
        <b-spinner small />
      </div>
      <div v-else>
        <!-- frontières -->
        <div v-if="$can('create', 'formulaireFrontiereSortie') || $can('create', 'formulaireFrontiereEntree') || can_watch_all_stats">
          <b-row class="match-height">
            <b-col xl="12" md="6">
              <ecommerce-statistics
                :data="seriesFrontièreMensuel"
                :title="isCommissariatUser ? 'Fiches d\'entrée et de sortie aux frontières terrestres dans votre commissariat' : 'Fiches d\'entrée et de sortie aux frontières terrestres'"
              />
            </b-col>
          </b-row>
        </div>
        <!-- restaurant -->
        <div
          v-if="$can('create', 'formulaireRestaurantMensuel') || can_watch_all_stats"
        >
          <b-row class="match-height">
            <b-col xl="12" md="6">
              <ecommerce-statistics
                :data="seriesStatsMensuelRestau"
                title="Fiches statistiques mensuelles des établissements de restauration"
              />
            </b-col>
          </b-row>
        </div>
        <!-- voyage -->
        <div
          v-if="$can('create', 'formulaireAgenceVoyageMensuel') || can_watch_all_stats"
        >
          <b-row class="match-height">
            <b-col xl="12" md="6">
              <ecommerce-statistics
                :data="seriesStatsMensuelVoyage"
                title="Fiches statistiques mensuelles des agences de voyages"
              />
            </b-col>
          </b-row>
        </div>
        <!-- transport -->
        <div
          v-if="$can('create', 'formulaireTransportMensuel') || can_watch_all_stats"
        >
          <b-row class="match-height">
            <b-col xl="12" md="6">
              <ecommerce-statistics
                :data="seriesStatsMensuelTransport"
                title="Fiches statistiques mensuelles des agences de transport de voyageurs"
              />
            </b-col>
          </b-row>
        </div>
        <!-- sport -->
        <div
          v-if="$can('create', 'formulaireServiceSportifRecreatif') || can_watch_all_stats"
        >
          <b-row class="match-height">
            <b-col xl="12" md="6">
              <ecommerce-statistics
                :data="seriesStatsMensuelSport"
                title="Fiches statistiques mensuelles des agences d'activités sportives et récréatives"
              />
            </b-col>
          </b-row>
        </div>
        <!-- culture -->
        <div
          v-if="
            $can('create', 'formulaireServiceCulturel') || can_watch_all_stats
          "
        >
          <b-row class="match-height">
            <b-col xl="12" md="6">
              <ecommerce-statistics
                :data="seriesStatsMensuelCulture"
                title="Fiches statistiques mensuelles des agences d'activités culturelles"
              />
            </b-col>
          </b-row>
        </div>
        <!-- location sans op -->
        <div
          v-if="
            $can('create', 'formulaireLocationVehiculeSansOpMensuel') ||can_watch_all_stats
          "
        >
          <b-row class="match-height">
            <b-col xl="12" md="6">
              <ecommerce-statistics
                :data="seriesStatsMensuelLocationSansOp"
                title="Fiches statistiques mensuelles des agences de location de véhicules sans opérateurs"
              />
            </b-col>
          </b-row>
        </div>
        <!-- hotel stat -->
        <div
            v-if="$can('create', 'formulaireHotelMensuel') || can_watch_all_stats"
        >
          <b-row class="match-height">
            <b-col xl="12" md="6">
              <ecommerce-statistics
                :data="seriesStatsMensuelHotel"
                title="Fiches statistiques mensuelles des établissements d'hébergement de voyageurs"
              />
            </b-col>
          </b-row>
        </div>

        <!-- hotel client -->
        <div v-if="$can('create', 'formulaireHotel') || can_watch_all_stats">
          <b-row class="match-height">
            <b-col xl="12" md="6">
              <ecommerce-statistics
                :data="seriesClientsMensuelHotel"
                title="Fiches clientes des établissements d'hébergement de voyageurs"
              />
            </b-col>
          </b-row>
          <b-row class="match-height">
            <b-col xl="6" md="6">
              <b-card>
                <b-card-title>
                  <strong> Nombre de clients par genre </strong> <br />
                </b-card-title>
                <vue-apex-charts
                  type="pie"
                  height="325"
                  class="mt-2 mb-1"
                  :options="seriesStatsClientsSexeBis.chartOptions"
                  :series="seriesStatsClientsSexeBis.series"
                />
              </b-card>
            </b-col>
            <b-col xl="6" md="6">
              <b-card>
                <b-card-title>
                  <strong>
                    Nombre de clients par categorie socio professionelle
                  </strong>
                </b-card-title>
                <vue-apex-charts
                  type="pie"
                  height="325"
                  class="mt-2 mb-1"
                  :options="seriesStatsClientsCatagorieSocioProBis.chartOptions"
                  :series="seriesStatsClientsCatagorieSocioProBis.series"
                />
              </b-card>
            </b-col>
          </b-row>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";

import { getUserData } from "@/auth/utils";
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BCardText,
  BRow,
  BCol,
  BButton,
  BCarousel,
  BCarouselSlide,
  BImg,
  BAlert,
  BSpinner,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
} from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";

import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import { mapActions } from "vuex";
import utilsService from "@/services/utils/utils.service";
import statisticsStroreModule from "@/store/statistics";
import paramsStroreModule from "@/store/params";
import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";
import AlertSwiperComponent from "@/components/AlertSwiperComponent.vue";
import EcommerceMedal from "./EcommerceMedal.vue";
import EcommerceStatistics from "./EcommerceStatistics.vue";
import EcommerceRevenueReport from "./EcommerceRevenueReport.vue";
import EcommerceOrderChart from "./EcommerceOrderChart.vue";
import EcommerceProfitChart from "./EcommerceProfitChart.vue";
import EcommerceEarningsChart from "./EcommerceEarningsChart.vue";
import EcommerceCompanyTable from "./EcommerceCompanyTable.vue";
import EcommerceMeetup from "./EcommerceMeetup.vue";
import EcommerceBrowserStates from "./EcommerceBrowserStates.vue";
import EcommerceGoalOverview from "./EcommerceGoalOverview.vue";
import EcommerceTransactions from "./EcommerceTransactions.vue";
import DateRangeWidget from "@/components/DateRangeWidget.vue";
import ChartjsComponentPolarAreaChart from "@/views/charts-and-maps/charts/chartjs/charts-components/ChartjsComponentPolarAreaChart.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
const {
  etablissementId,
  commissariatId,
  isCommissariatUser,
  isSuperAdmin,
  isAgentMtca,
  isAgentDdtca,
  userData,
  isAgentPolice
} = utilsService.currentUserUtils();

const chartColors = [
  "#836AF9",
  "#ffe800",
  "#F51B05",
  "#28dac6",
  "#FDAC34",
  "#299AFF",
  "#4F5D70",
  "#2c9aff",
  "#84D0FF",
  "#EDF1F4",
  "#ffe802",
  "#666ee8",
  "#ff4961",
  "#6e6b7b",
  "rgba(0, 0, 0, 0.25)",
  "rgba(200, 200, 200, 0.2)",
];

const listFormulaires = utilsService.getListFormulairesForDashboard();
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    BCardText,
    BButton,
    VueApexCharts,
    BCarousel,
    BCarouselSlide,
    BSpinner,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,

    Swiper,
    SwiperSlide,
    BImg,
    BAlert,

    BCardCode,

    EcommerceMedal,
    EcommerceStatistics,
    EcommerceRevenueReport,
    EcommerceOrderChart,
    EcommerceProfitChart,
    EcommerceEarningsChart,
    EcommerceCompanyTable,
    EcommerceMeetup,
    EcommerceBrowserStates,
    EcommerceGoalOverview,
    EcommerceTransactions,
    AlertSwiperComponent,
    ChartjsComponentPolarAreaChart,
    DateRangeWidget,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  setup() {
    const requiredStoreModules = [
      { path: "statistics", module: statisticsStroreModule },
      { path: "params", module: paramsStroreModule },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);

    return { requiredStoreModules };
  },
  data() {
    return {
      etablissementId,
      commissariatId,
      isCommissariatUser,
      isSuperAdmin,
      isAgentMtca,
      isAgentDdtca,
      userData,
      isAgentPolice,
      // odeVirtual,
      listFormulaires: listFormulaires.filter((e) =>
        this.$can("create", e.resource)
      ),
      slides: [],
      prependNumber: 1,
      appendNumber: 600,
      swiperOptions: {
        slidesPerView: 3,
        centeredSlides: true,
        spaceBetween: 30,
        pagination: {
          el: ".swiper-pagination",
          type: "fraction",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        virtual: {
          slides: Array(600)
            .fill("Slide")
            .map((item, index) => `${item} ${index + 1}`),
        },
      },
      myMomentToday: moment(),

      // hum
      swiperData: [
        {
          title: "Slide 1",
          subtitle: "Subtitle",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam dictum mattis velit, sit amet faucibus felis iaculis nec. Nulla laoreet Lorem, ipsum dolor sit amet consectetur..",
        },
        {
          title: "Slide 2",
          subtitle: "Subtitle",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam dictum mattis velit, sit amet faucibus felis iaculis nec. Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam reiciendis provident atque quod obcaecati voluptatibus ex qui repudiandae sunt dolores. Nulla laoreet justo vitae porttitor porttitor. Suspendisse in sem justo. Integeo. Aenean feugiat non eros quis feugiat.",
        },
        {
          title: "Slide 3",
          subtitle: "Subtitle",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam dictum mattis velit, sit amet faucibus felis iaculis nec. Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam reiciendis provident atque quod obcaecati voluptatibus ex qui repudiandae sunt dolores. Nulla laoreet justo vitae porttitor porttitor. Suspendisse in sem justo. Integer laoreet magna nec elit suscipit, ac laoreet nibh euismod. Aliquam hendrerit lnt ut libero. Aenean feugiat non eros quis feugiat.",
        },
      ],
      swiperOptions: {
        speed: 600,
        parallax: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },

      // swiper
      swiperData: [
        { icon: "GithubIcon", text: "Getting Started" },
        { icon: "FacebookIcon", text: "Pricing & Plans" },
        { icon: "TwitterIcon", text: "Sales Question" },
        { icon: "InstagramIcon", text: "Usage Guides" },
        { icon: "GitlabIcon", text: "General Guide" },
      ],
      swiperOptions: {
        slidesPerView: "auto",
        centeredSlides: true,
        spaceBetween: 30,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },

      data: {},
      // salesLine
      salesLineCA: {
        series: [
          {
            name: "Valeur",
            data: [140, 180, 150, 205, 160, 295, 125, 255, 205, 305, 240, 295],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
            type: "line",
            dropShadow: {
              enabled: true,
              top: 18,
              left: 2,
              blur: 5,
              opacity: 0.2,
            },
            offsetX: -10,
          },
          stroke: {
            curve: "smooth",
            width: 4,
          },
          grid: {
            borderColor: "#ebe9f1",
            padding: {
              top: -20,
              bottom: 5,
              left: 20,
            },
          },
          legend: {
            show: false,
          },
          colors: ["#df87f2"],
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              inverseColors: false,
              gradientToColors: [$themeColors.primary],
              shadeIntensity: 1,
              type: "horizontal",
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100],
            },
          },
          markers: {
            size: 0,
            hover: {
              size: 5,
            },
          },
          xaxis: {
            labels: {
              offsetY: 5,
              style: {
                colors: "#b9b9c3",
                fontSize: "0.857rem",
              },
            },
            axisTicks: {
              show: false,
            },
            categories: [
              "Jan",
              "Fev",
              "Mars",
              "Avril",
              "Mai",
              "Juin",
              "Jullet",
              "Aout",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
            axisBorder: {
              show: false,
            },
            tickPlacement: "on",
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                colors: "#b9b9c3",
                fontSize: "0.857rem",
              },
              formatter(val) {
                return val > 999 ? `${(val / 1000).toFixed(1)}k` : val;
              },
            },
          },
          tooltip: {
            x: { show: false },
          },
        },
      },
      salesLineCS: {
        series: [
          {
            name: "Valeur",
            data: [140, 180, 150, 205, 160, 295, 125, 255, 205, 305, 240, 295],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
            type: "line",
            dropShadow: {
              enabled: true,
              top: 18,
              left: 2,
              blur: 5,
              opacity: 0.2,
            },
            offsetX: -10,
          },
          stroke: {
            curve: "smooth",
            width: 4,
          },
          grid: {
            borderColor: "#ebe9f1",
            padding: {
              top: -20,
              bottom: 5,
              left: 20,
            },
          },
          legend: {
            show: false,
          },
          colors: ["#df87f2"],
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              inverseColors: false,
              gradientToColors: [$themeColors.primary],
              shadeIntensity: 1,
              type: "horizontal",
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100],
            },
          },
          markers: {
            size: 0,
            hover: {
              size: 5,
            },
          },
          xaxis: {
            labels: {
              offsetY: 5,
              style: {
                colors: "#b9b9c3",
                fontSize: "0.857rem",
              },
            },
            axisTicks: {
              show: false,
            },
            categories: [
              "Jan",
              "Fev",
              "Mars",
              "Avril",
              "Mai",
              "Juin",
              "Jullet",
              "Aout",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
            axisBorder: {
              show: false,
            },
            tickPlacement: "on",
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                colors: "#b9b9c3",
                fontSize: "0.857rem",
              },
              formatter(val) {
                return val > 999 ? `${(val / 1000).toFixed(1)}k` : val;
              },
            },
          },
          tooltip: {
            x: { show: false },
          },
        },
      },
      salesLineAD: {
        series: [
          {
            name: "Valeur",
            data: [140, 180, 150, 205, 160, 295, 125, 255, 205, 305, 240, 295],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
            type: "line",
            dropShadow: {
              enabled: true,
              top: 18,
              left: 2,
              blur: 5,
              opacity: 0.2,
            },
            offsetX: -10,
          },
          stroke: {
            curve: "smooth",
            width: 4,
          },
          grid: {
            borderColor: "#ebe9f1",
            padding: {
              top: -20,
              bottom: 5,
              left: 20,
            },
          },
          legend: {
            show: false,
          },
          colors: ["#df87f2"],
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              inverseColors: false,
              gradientToColors: [$themeColors.primary],
              shadeIntensity: 1,
              type: "horizontal",
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100],
            },
          },
          markers: {
            size: 0,
            hover: {
              size: 5,
            },
          },
          xaxis: {
            labels: {
              offsetY: 5,
              style: {
                colors: "#b9b9c3",
                fontSize: "0.857rem",
              },
            },
            axisTicks: {
              show: false,
            },
            categories: [
              "Jan",
              "Fev",
              "Mars",
              "Avril",
              "Mai",
              "Juin",
              "Jullet",
              "Aout",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
            axisBorder: {
              show: false,
            },
            tickPlacement: "on",
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                colors: "#b9b9c3",
                fontSize: "0.857rem",
              },
              formatter(val) {
                return val > 999 ? `${(val / 1000).toFixed(1)}k` : val;
              },
            },
          },
          tooltip: {
            x: { show: false },
          },
        },
      },
      // donuts
      chartData: {},
      sessionsByDeviceDonutTempsPleinSexe: {
        series: [30, 70],
        chartOptions: {
          chart: {
            toolbar: {
              show: true,
            },
          },
          labels: ["Hommes", "Femmes"],
          dataLabels: {
            enabled: false,
          },
          legend: { show: true },
          // comparedResult: [2, -3],
          stroke: { width: 0 },
          colors: [$themeColors.primary, $themeColors.warning],
        },
      },
      sessionsByDeviceDonutTempsPleinNationalite: {
        series: [30, 30, 40],
        chartOptions: {
          chart: {
            toolbar: {
              show: true,
            },
          },
          labels: ["Bénin", "Togo", "Niger"],
          dataLabels: {
            enabled: false,
          },
          legend: { show: true },
          // comparedResult: [2, -3],
          stroke: { width: 0 },
          colors: [
            $themeColors.primary,
            $themeColors.warning,
            $themeColors.danger,
          ],
        },
      },
      sessionsByDeviceDonutTempsPartiel: {
        series: [60, 40],
        chartOptions: {
          chart: {
            toolbar: {
              show: true,
            },
          },
          labels: ["Hommes", "Femmes"],
          dataLabels: {
            enabled: false,
          },
          legend: { show: true },
          comparedResult: [2, -3, 8],
          stroke: { width: 0 },
          colors: [
            $themeColors.primary,
            $themeColors.warning,
            $themeColors.danger,
          ],
        },
      },

      paginationData: {
        currentPage: 1,
        total: 0,
        perPage: 10,
        metaData: { from: 0, to: 0 },
      },
      agregats: null,

      // ------------------------------------------------
      loadingIndicators: false,
      indicators: null,

      // seriesFrontièreEntreeMensuel: [],
      // seriesFrontièreSortieMensuel: [],
      seriesFrontièreMensuel: [],
      seriesStatsMensuelRestau: [],
      seriesStatsMensuelTransport: [],
      seriesStatsMensuelSport: [],
      seriesStatsMensuelCulture: [],
      seriesStatsMensuelLocationSansOp: [],
      seriesStatsMensuelVoyage: [],
      seriesStatsMensuelLocation: [],
      seriesStatsMensuelHotel: [],
      seriesClientsMensuelHotel: [],
      // seriesStatsClientsSexe: {
      //   labels: [],
      //   datasets: [
      //     {
      //       label: "",
      //       backgroundColor: [],
      //       data: [],
      //       borderWidth: 0,
      //     },
      //   ],
      // },
      seriesStatsClientsSexeBis: {
        series: [],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          labels: [],
          dataLabels: {
            enabled: true,
          },
          legend: { show: false },
          stroke: {
            width: 4,
          },
          colors: [],
        },
      },
      // seriesStatsClientsCatagorieSocioPro: {
      //   labels: [],
      //   datasets: [
      //     {
      //       label: "",
      //       backgroundColor: [],
      //       data: [],
      //       borderWidth: 0,
      //     },
      //   ],
      // },
      seriesStatsClientsCatagorieSocioProBis: {
        series: [],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          labels: [],
          dataLabels: {
            enabled: true,
          },
          legend: { show: false },
          stroke: {
            width: 4,
          },
          colors: [],
        },
      },
      currentColorSelected: 0,
      filterBy: {
        periode: {},
      },
    };
  },
  watch: {
    $route: {
      immediate: true,
      async handler(val, old) {
        this.init_filter_period()
        setTimeout(() => {
          this.loadIndicators(); // launch indicators laoding
        }, 500);
      },
    },
    "filterBy.periode": {
      // immediate: true ,
      deep: true,
      async handler(val, old) {
        const result_from = this.date_is_after_today(val.from)
        const result_to = this.date_is_after_today(val.to)
        if( result_from || result_to ){
          // alerte
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "La période choisie ne peut excéder la date actuelle. Veuillez actualiser la période indiquée.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          // reset
          // this.init_filter_period()
        }
      },
    },
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },
  computed: {
    can_watch_all_stats() {
      return this.isSuperAdmin || this.isAgentMtca || this.isAgentDdtca
    }
  },
  mounted() {},
  methods: {
    ...mapActions("statistics", {
      fetchStatisticsTable: "fetchStatisticsTable",
      fetchAgregats: "fetchAgregats",
    }),
    ...mapActions("params", {
      action_fetchIndicators: "fetchIndicators",
    }),
    // ---------------------------------------------------------
    toSlide(index) {
      this.$refs.mySwiper15.$swiper.slideTo(index, 1);
    },
    prependSlides() {
      this.$refs.mySwiper15.$swiper.virtual.prependSlide([
        `Slide ${(this.prependNumber -= 1)}`,
        `Slide ${(this.prependNumber -= 1)}`,
      ]);
    },
    appendSlide() {
      this.$refs.mySwiper15.$swiper.virtual.appendSlide(
        `Slide ${(this.appendNumber += 1)}`
      );
    },
    loadAgregats() {
      const params = {
        page: this.paginationData.currentPage - 1,
        perPage: this.paginationData.perPage,
      };
      this.fetchAgregats(params)
        .then((res) => {
          if (res.status == 200) {
            this.agregats = res.data.agregats;
            // console.log('this.agregat', this.agregats)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async loadIndicators() {
      const result_to = this.date_is_after_today(this.filterBy.periode.to)
      const result_from = this.date_is_after_today(this.filterBy.periode.from)
      if( result_to || result_from ){
        // alerte
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "La période choisie ne peut excéder la date actuelle. Veuillez actualiser la période indiquée.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
        // reset
        // this.init_filter_period()
        return
      }
      this.loadingIndicators = true;
      
      let params = {
        domaine: "",
        id: "",
        filterData: {
          type: ["createdAt"],
          text: [],
        },
      };
      // load sector and id
      if (this.etablissementId) {
        params.domaine = "etablissement";
        params.id = this.etablissementId;
      }
      if (this.commissariatId || this.isCommissariatUser) {
        params.domaine = "frontiere";
        params.id = this.commissariatId ? this.commissariatId : this.userData._id;
      }
      // load period
      if (this.filterBy.periode.from && this.filterBy.periode.to) {
        let array = [];
        array.push(this.filterBy.periode.from, this.filterBy.periode.to);
        params.filterData.text.push(array.join());
      }
      if (this.isSuperAdmin || this.isAgentMtca || this.isAgentDdtca) {
        // loading for admin or agent mtca

        let listOfEstablishmentSector = [
          "hotels",
          "agence_restau",
          "agence_voyage",
          "agence_transport",
          "agence_location",
          "activite_sportive",
          "activite_culturelle",
        ];
        let listOfDomaine = ["etablissement", "frontiere"];

        listOfDomaine.forEach(async (domaine, indexDomaine) => {
          // --------------------
          // treatment for establishment
          if (indexDomaine === 0) {
            for (
              let indexEtabSector = 0;
              indexEtabSector < listOfEstablishmentSector.length;
              indexEtabSector++
            ) {
              const establishmentSector =
                listOfEstablishmentSector[indexEtabSector];
              params.domaine = domaine;
              params.codeTypeEtab = establishmentSector;
              await this.load_data(params);
            }
          }
          // treatment for frontier
          if (indexDomaine === 1) {
            params.domaine = domaine;
            params.codeTypeEtab = null;
            await this.load_data(params);
          }
        });
      } else {
        // loading for promoteur or agent etablissement
        this.load_data(params);
      }
    },

    async load_data(params) {
      // console.log("params:  🔵 🔵 🔵", params);
      // inititalize data
      await this.action_fetchIndicators(params)
        .then((res) => {
          if (res.status == 200) {
            this.loadingIndicators = false;
            // console.log(
            //   " 🔵⭕️⭕️⭕️⭕️⭕️ res loadIndicators: ",
            //   res.data.resources
            // );
            this.indicators = res.data.resources;
            this.seriesStatsMensuel = [];

            this.indicators.map((item, index) => {
              // stat hotel
              if (item.table === "formstathotel") {
                // console.log('stat hotel')
                this.seriesStatsMensuelHotel = [];
                this.seriesStatsMensuelHotel = [
                  {
                    icon: "la-paste",
                    color: "light-danger",
                    title: item.total,
                    subtitle: "Fiches",
                    customClass: "null",
                  },
                  {
                    icon: "la-hand-holding-usd",
                    color: "light-primary",
                    title: item.total_charges_salariales,
                    subtitle: "Total Charges Salariales",
                    customClass: "null",
                  },
                  {
                    icon: "la-door-open",
                    color: "light-primary",
                    title: item.total_arrives,
                    subtitle: "Total Arrivées",
                    customClass: "null",
                  },
                  {
                    icon: "la-bed",
                    color: "light-primary",
                    title: item.total_nuites,
                    subtitle: "Total Nuitées",
                    customClass: "null",
                  },
                ];
              }

              // client hotel
              if (item.table === "formclienthotel") {
                // console.log("formclienthotel::: ⭕️", res.data.resources);
                this.seriesClientsMensuelHotel = [];
                // total
                this.seriesClientsMensuelHotel = [
                  {
                    icon: "la-paste",
                    color: "light-danger",
                    title: item.total,
                    subtitle: "Fiches",
                    customClass: "null",
                  },
                ];
                // sexe
                this.seriesStatsClientsSexeBis = {
                  series: [],
                  chartOptions: {
                    chart: {
                      toolbar: {
                        show: false,
                      },
                    },
                    labels: [],
                    dataLabels: {
                      enabled: true,
                    },
                    legend: { show: false },
                    stroke: {
                      width: 4,
                    },
                    colors: [],
                  },
                };
                item.total_by_sexe.map((row) => {
                  if (row.value > 0) {
                    this.seriesStatsClientsSexeBis.chartOptions.labels.push(
                      row.label
                    );
                    this.seriesStatsClientsSexeBis.chartOptions.colors.push(
                      this.randomColor()
                    );
                    this.seriesStatsClientsSexeBis.series.push(row.value);
                  }
                });
                // catagorie soci pro
                this.seriesStatsClientsCatagorieSocioProBis = {
                  series: [],
                  chartOptions: {
                    chart: {
                      toolbar: {
                        show: false,
                      },
                    },
                    labels: [],
                    dataLabels: {
                      enabled: true,
                    },
                    legend: { show: false },
                    stroke: {
                      width: 4,
                    },
                    colors: [],
                  },
                };
                item.total_by_categorie_socio_pro.map((row) => {
                  // console.log("row::: ", row);
                  if (row.value > 0) {
                    this.seriesStatsClientsCatagorieSocioProBis.chartOptions.labels.push(
                      row.label
                    );
                    this.seriesStatsClientsCatagorieSocioProBis.chartOptions.colors.push(
                      this.randomColor()
                    );
                    this.seriesStatsClientsCatagorieSocioProBis.series.push(
                      row.value
                    );
                  }
                });
              }

              // stat restau
              if (item.table === "formstatrestau") {
                this.seriesStatsMensuelRestau = [];
                // console.log('stat restau')
                this.seriesStatsMensuelRestau = [
                  {
                    icon: "la-paste",
                    color: "light-danger",
                    title: item.total,
                    subtitle: "Fiches",
                    customClass: "null",
                  },
                  {
                    icon: "la-user-tie",
                    color: "light-primary",
                    title: item.total_employes_salariers,
                    subtitle: "Total Employés Salariés",
                    customClass: "null",
                  },
                  {
                    icon: "la-chair",
                    color: "light-primary",
                    title: item.total_places_disponibles,
                    subtitle: "Total Place disponibles",
                    customClass: "null",
                  },
                ];
              }

              // stat transport
              if (item.table === "formstattransport") {
                this.seriesStatsMensuelTransport = [];
                // console.log('stat transport')
                this.seriesStatsMensuelTransport = [
                  {
                    icon: "la-paste",
                    color: "light-danger",
                    title: item.total,
                    subtitle: "Fiches",
                    customClass: "null",
                  },
                  {
                    icon: "la-wallet",
                    color: "light-primary",
                    title: item.chiffreAffaireGlobal,
                    subtitle: "Total Chiffre d'affaire globale",
                    customClass: "null",
                  },
                  {
                    icon: "la-user-tie",
                    color: "light-primary",
                    title: item.total_employes_salariers,
                    subtitle: "Total Employés Salariés",
                    customClass: "null",
                  },
                  {
                    icon: "la-bus-alt",
                    color: "light-primary",
                    title: item.total_voyageurs_transporter,
                    subtitle: "Total Voyageurs Transportés",
                    customClass: "null",
                  },
                ];
              }

              // stat sport
              if (item.table === "formstatsport") {
                this.seriesStatsMensuelSport = [];
                this.seriesStatsMensuelSport = [
                  {
                    icon: "la-paste",
                    color: "light-danger",
                    title: item.total,
                    subtitle: "Fiches",
                    customClass: "null",
                  },
                  {
                    icon: "la-wallet",
                    color: "light-primary",
                    title: item.chiffreAffaireGlobal,
                    subtitle: "Total Chiffre d'affaire globale",
                    customClass: "null",
                  },
                  {
                    icon: "la-user-tie",
                    color: "light-primary",
                    title: item.total_employes_salariers,
                    subtitle: "Total Employés Salariés",
                    customClass: "null",
                  },
                  {
                    icon: "la-bus-alt",
                    color: "light-primary",
                    title: item.total_visiteurs,
                    subtitle: "Total Visiteurs",
                    customClass: "null",
                  },
                ];
              }

              // stat culture
              if (item.table === "formstatculture") {
                this.seriesStatsMensuelCulture = [];
                this.seriesStatsMensuelCulture = [
                  {
                    icon: "la-paste",
                    color: "light-danger",
                    title: item.total,
                    subtitle: "Fiches",
                    customClass: "null",
                  },
                  {
                    icon: "la-wallet",
                    color: "light-primary",
                    title: item.chiffreAffaireGlobal,
                    subtitle: "Total Chiffre d'affaire globale",
                    customClass: "null",
                  },
                  {
                    icon: "la-user-tie",
                    color: "light-primary",
                    title: item.total_employes_salariers,
                    subtitle: "Total Employés Salariés",
                    customClass: "null",
                  },
                  {
                    icon: "la-bus-alt",
                    color: "light-primary",
                    title: item.total_visiteurs,
                    subtitle: "Total Visiteurs",
                    customClass: "null",
                  },
                ];
              }

              // stat location sans op
              if (item.table === "formstatlocationvehiculesansop") {
                this.seriesStatsMensuelLocationSansOp = [];
                // console.log('stat location sans op')
                this.seriesStatsMensuelLocationSansOp = [
                  {
                    icon: "la-paste",
                    color: "light-danger",
                    title: item.total,
                    subtitle: "Fiches",
                    customClass: "null",
                  },
                  {
                    icon: "la-wallet",
                    color: "light-primary",
                    title: item.chiffreAffaireGlobal,
                    subtitle: "Total Chiffre d'affaire globale",
                    customClass: "null",
                  },
                  {
                    icon: "la-user-tie",
                    color: "light-primary",
                    title: item.total_employes_salariers,
                    subtitle: "Total Employés Salariés",
                    customClass: "null",
                  },
                  {
                    icon: "la-car-side",
                    color: "light-primary",
                    title: item.total_vehicules_jour,
                    subtitle: "Total Véhicules Loués par jour",
                    customClass: "null",
                  },
                ];
              }

              // stat voyage
              if (item.table === "formstatagence") {
                this.seriesStatsMensuelVoyage = [];
                // console.log('stat voyage')
                this.seriesStatsMensuelVoyage = [
                  {
                    icon: "la-paste",
                    color: "light-danger",
                    title: item.total,
                    subtitle: "Fiches",
                    customClass: "null",
                  },
                  {
                    icon: "la-user-tie",
                    color: "light-primary",
                    title: item.total_employes_salariers,
                    subtitle: "Total Employés Salariés",
                    customClass: "null",
                  },
                  {
                    icon: "la-road",
                    color: "light-primary",
                    title: item.total_voyages_internes,
                    subtitle: "Total Voyages dans le pays",
                    customClass: "null",
                  },
                  {
                    icon: "la-globe-africa",
                    color: "light-primary",
                    title: item.total_voyages_externes,
                    subtitle: "Total Voyages Internationaux",
                    customClass: "null",
                  },
                ];
              }
              // frontiere entree
              if (item.table === "formfrontiereentreevoyageur") {
                // console.log("formfrontiereentreevoyageur::: ⭕️", item);
                this.seriesFrontièreMensuel = [];
                this.seriesFrontièreMensuel.push({
                  icon: "la-sign-in-alt",
                  color: "light-danger",
                  title: item.total_fiches,
                  subtitle: "Fiches Entrées Frontière",
                  customClass: "null",
                });
              }
              // frontiere sortie
              if (item.table === "formfrontieresortievoyageur") {
                // console.log("formfrontieresortievoyageur::: ⭕️", item);
                this.seriesFrontièreMensuel.push({
                  icon: "la-sign-out-alt",
                  color: "light-danger",
                  title: item.total_fiches,
                  subtitle: "Fiches Sorties Frontière",
                  customClass: "null",
                });
              }
            });
          }
        })
        .catch((err) => {
          this.loadingIndicators = false;
          console.log("err loadIndicators : ", err);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || "Une erreur est survenue ! ",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    // ----------------------
    randomColor() {
      // let color = chartColors[this.currentColorSelected];
      let color = chartColors[Math.floor(Math.random() * chartColors.length)];
      this.currentColorSelected += 1;
      return color;
    },
    init_filter_period(){
      // load initial period
      const startOfMonth = "2020/01/01";
      const today = this.myMomentToday.format("YYYY/MM/DD")
      this.filterBy.periode = {
        from: startOfMonth,
        to: today,
      };
    },
    date_is_after_today(string_date){
      const myMomentDate = moment(string_date, 'YYYY-MM-DD')
      return myMomentDate.isAfter(this.myMomentToday)
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>

<style scoped lang="scss">
div.scrollmenu {
  // background-color: #333;
  color: #000;
  overflow: auto;
  white-space: nowrap;
}

div.scrollmenu div {
  display: inline-block;
  // color: white;
  text-align: center;
  padding: 14px;
  text-decoration: none;
}

div.scrollmenu div:hover {
  // background-color: #777;
}

.customCard:hover {
  background-color: #0e6258 !important;
  color: #fff;

  .card-title {
    color: #fff;
  }
}

.carouselStyle {
  height: 1000px;
}

::v-deep .carousel-item {
  img {
    height: 500px;
    object-fit: cover;
  }
}
</style>
